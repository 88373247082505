import React from 'react'
import cx from 'classnames'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/jaky-je-spravny-trojpomer-zivin.jpg'
import styles from './post-grid.module.css'

const JakyJeSpravnyTrojpomerZivin = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Jaký je správný trojpoměr živin? | NutritionPro'}
        description={
          'Určit správný trojpoměr živin v naší stravě není vždy snadné. Často se setkáváme s různými názory a trendy, které nám radí, co jíst a co naopak vynechat. Na internetu také najdete nespočet kalkulaček, které vám nějaký trojpoměr živin vypočítají. Rovnováha mezi třemi základními živinami - bílkovinami, sacharidy a tuky - je velmi důležitá pro náš zdravý život a funkčnost organismu. V této době, kdy se však spoustu lidí snaží zhubnout nebo naopak nabrat svalovou hmotu, se objevuje mnoho diet, které přinášejí různé teorie o tom, jaký poměr živin je nejvhodnější pro daný cíl. V tomto článku se podíváme na to, jaký je vhodný trojpoměr živin pro běžnou populaci a jiné specifické skupiny, a také na to, jak se s ním dá pracovat pro dosažení vašich cílů.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/jaky-je-spravny-trojpomer-zivin">
            Jaký je správný trojpoměr živin?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="Jaký je správný trojpoměr živin?" date="16.04.2023" />
            <div>
              <p className={styles.postText}>
                Určit správný trojpoměr živin v naší stravě není vždy snadné.
                Často se setkáváme s různými názory a trendy, které nám radí,{' '}
                <b>co jíst a co naopak vynechat</b>. Na internetu také najdete{' '}
                <b>nespočet kalkulaček</b>, které vám nějaký trojpoměr živin
                vypočítají. Rovnováha mezi třemi základními živinami -{' '}
                <b>bílkovinami, sacharidy a tuky</b> - je velmi důležitá pro náš
                zdravý život a funkčnost organismu. V této době, kdy se však
                spoustu lidí snaží zhubnout nebo naopak nabrat svalovou hmotu,
                se objevuje mnoho diet, které přinášejí různé teorie o tom,{' '}
                <b>jaký poměr živin je nejvhodnější pro daný cíl</b>. V tomto
                článku se podíváme na to, jaký je{' '}
                <b>vhodný trojpoměr živin pro běžnou populaci</b> a jiné
                specifické skupiny, a také na to, jak se s ním dá pracovat pro
                dosažení vašich cílů.
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v miskách"
              />

              <h5 className={styles.postTitle}>Základní trojpoměr</h5>
              <p className={styles.postText}>
                Základní trojpoměr živin vypadá následovně:
              </p>
              <ul className={styles.postList}>
                <li>
                  <b>Sacharidy:</b> {'>'} 50 % denního energetického příjmu
                </li>
                <li>
                  <b>Bílkoviny:</b> okolo 15 % denního energetického příjmu
                </li>
                <li>
                  <b>Tuky:</b> cca 30 % denního energetického příjmu
                </li>
              </ul>

              <p className={styles.postText}>
                Tento trojpoměr se <b>může lišit</b> v závislosti na věku,
                pohlaví, tělesné aktivitě a mnoha dalších faktorech.{' '}
                <b>Stabilní</b> by měl zůstat <b>příjem sacharidů</b> (okolo 50
                %), příjem <b>bílkovin a tuků</b> se může{' '}
                <b>lišit či obměňovat</b>. Pro představu, příjem bílkovin by měl
                činit 0,8 - 1 g/kg tělesné hmotnosti, to znamená, že pokud
                vážíte 70 kilo, měli byste za den sníst cca 56-70 gramů
                bílkovin.{' '}
                <b>Celkový energetický příjem i příjem konkrétních živin</b> je
                však vždy <b>individuální</b>.
              </p>

              <h5 className={styles.postTitle}>Hubnoucí osoby</h5>
              <p className={styles.postText}>
                Trojpoměr hubnoucí <b>ženy</b> může vypadat zhruba takto:{' '}
              </p>
              <ul className={styles.postList}>
                <li>
                  <b>Sacharidy:</b> 51 % denního energetického příjmu
                </li>
                <li>
                  <b>Bílkoviny:</b> 19 % denního energetického příjmu
                </li>
                <li>
                  <b>Tuky:</b> 30 % denního energetického příjmu
                </li>
              </ul>

              <p className={styles.postText}>
                Jak vidíte, pozměnil se v tomto případě poměr{' '}
                <b>sacharidů a bílkovin</b>. Příjem sacharidů je pořád vyšší než
                50 %, příjem bílkovin se navýšil a tuky zůstaly stejné. Navýšení
                bílkovin při redukci je důležité kvůli{' '}
                <b>ochraně svalové hmoty</b> a také <b>pocitu sytosti</b>.
                Bílkoviny jsou totiž nejvíce sytící složkou potravy.
              </p>

              <p className={styles.postText}>
                Trojpoměr hubnoucího <b>muže</b>:
              </p>
              <ul className={styles.postList}>
                <li>
                  <b>Sacharidy:</b> 46 % denního energetického příjmu
                </li>
                <li>
                  <b>Bílkoviny:</b> 24 % denního energetického příjmu
                </li>
                <li>
                  <b>Tuky:</b> 30 % denního energetického příjmu
                </li>
              </ul>

              <p className={styles.postText}>
                I zde se mění poměr mezi sacharidy a bílkovinami{' '}
                <b>ve prospěch bílkovin</b>.
              </p>

              <p className={styles.postText}>
                Pokud se snažíte <b>zhubnout</b>, je{' '}
                <b>správně nastavený trojpoměr</b> živin velmi <b>důležitý</b>,
                stejně jako celkový energetický příjem, který má každý z vás{' '}
                <b>nastaven individuálně podle vašeho životního stylu</b>. Pokud
                si trojpoměr živin nedokážete převést do praktického života,
                vyzkoušejte{' '}
                <a href="https://nutritionpro.cz" target="_blank">
                  NutritionPro krabičky.
                </a>{' '}
                Vypočítáme za vás jak váš celkový příjem, tak i optimum
                sacharidů, tuků a bílkovin. Stejně tak budeme mít jistotu, že
                jsou potraviny, které konzumujete, <b>kvalitní</b>, stejně jako{' '}
                <b>zdroje živin</b>, což je nejen při hubnutí <b>zásadní</b>.
              </p>

              <h5 className={styles.postTitle}>Sportovci</h5>
              <p className={styles.postText}>
                U sportovců je poměr živin opět <b>individuální</b>. Vždy je
                nutné zjistit, jakému sportu se daný člověk věnuje a{' '}
                <b>poradit se s odborníkem,</b> jaký jídelníček je nejvhodnější
                a na jak dlouhou dobu. Obecný trojpoměr živin{' '}
                <b>vytrvalostních sportovců</b> může být následující:
              </p>

              <ul className={styles.postList}>
                <li>
                  <b>Sacharidy:</b> 70 % denního energetického příjmu
                </li>
                <li>
                  <b>Bílkoviny:</b> 12 % denního energetického příjmu
                </li>
                <li>
                  <b>Tuky:</b> 18 % denního energetického příjmu
                </li>
              </ul>

              <p className={styles.postText}>
                Vytrvalostní sportovci potřebují pro své výkony{' '}
                <b>hodně energie</b> a ta se nejúčinněji získává ze{' '}
                <b>sacharidů</b>, které jsou hlavním zdrojem paliva pro svaly
                při fyzické aktivitě. Z tohoto důvody mají vytrvalostní
                sportovci navýšené množství sacharidů v jídelníčku.
              </p>

              <p className={styles.postText}>
                U <b>silových sportovců</b> může poměr živin vypadat následovně:
              </p>

              <ul className={styles.postList}>
                <li>
                  <b>Sacharidy:</b> 55 % denního energetického příjmu
                </li>
                <li>
                  <b>Bílkoviny:</b> 30 % denního energetického příjmu
                </li>
                <li>
                  <b>Tuky:</b> 15 % denního energetického příjmu
                </li>
              </ul>

              <p className={styles.postText}>
                Zde je důležitý dostatečný příjem zdravých tuků i sacharidů a
                navýšeny jsou hlavně bílkoviny, a to kvůli{' '}
                <b>růstu, obnově a regeneraci svalů.</b>
              </p>

              <h5 className={styles.postTitle}>Senioři</h5>
              <p className={styles.postText}>
                Trojpoměr živin u seniorů je téměř totožný s klasickým základním
                trojpoměrem, klade se však větší důraz na{' '}
                <b>dostatek bílkovin</b>:
              </p>

              <ul className={styles.postList}>
                <li>
                  <b>Sacharidy:</b> {'>'} 50 % denního energetického příjmu
                </li>
                <li>
                  <b>Bílkoviny:</b> 15-18 % denního energetického příjmu
                </li>
                <li>
                  <b>Tuky:</b> 30 % denního energetického příjmu
                </li>
              </ul>

              <p className={styles.postText}>
                Dalšími specifickými skupinami jsou <b>těhotné a kojící ženy</b>
                , u kterých se zvyšuje potřeba <b>tuků</b>, a to až na{' '}
                <b>35 %</b> z celkového energetického příjmu. Další skupinou
                jsou <b>děti</b>, u kterých je potřeba <b>tuků</b> v prvních
                měsících života až <b>50 %</b> a s věkem klesá až na <b>30 %</b>
                . Potřeba <b>bílkovin</b> u novorozenců je cca <b>2,7 g/kg</b>{' '}
                tělesné hmotnosti, což opět s věkem klesá. Jídelníček{' '}
                <b>předškoláků</b> už vypadá víceméně obdobně, jako jídelníček
                dospělého člověka, jen s nižší energetickou hodnotou (potřeba
                tuků a bílkovin je lehce vyšší). Nejdůležitější je, jako vždy,{' '}
                <b>kvalita potravin</b>, a to nejen u výše zmíněných skupin, ale
                u každého z nás.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Nezapomínejte ani na minerální látky a vitaminy!
                Důležitými vitaminy jsou vitaminy skupiny B, o kterých si můžete
                přečíst v{' '}
                <a
                  href="https://nutritionpro.cz/blog/vitamin-B/"
                  target="_blank"
                >
                  tomto článku
                </a>
                .
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default JakyJeSpravnyTrojpomerZivin
